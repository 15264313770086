<template>
  <div class="pagebox">
    
    <!-- 动态展示模块组件 -->
    <component :is="item.ModuleCode" :ModuleName="item.ModuleName" v-for="(item,index) in ModuleData" :key="index" class="wow fadeIn" data-wow-duration="0.8s" data-wow-delay="0s" data-wow-offset="100"></component>

  </div>
</template>
<script>
//引入wowjs
import {WOW} from 'wowjs';
import 'wowjs/css/libs/animate.css';
import Top from '@/components/header/Top.vue';
import Head from '@/components/header/Header.vue';
import HeadMenu from '@/components/header/HeadMenu.vue';
import Banner from "./components/home/HBanner.vue";
import Notice from "./components/home/HNotice.vue";
import ToolBox from "./components/home/HBotton.vue";
import News from "./components/home/HNews.vue";
import Planner from "./components/home/HExpert.vue";
import UpgradeNews from "./components/home/HRiseNews.vue";
import HotSpecialty from "./components/home/HSpeciality.vue";
import HotSchool from "./components/home/HSchool.vue";
import Video from "./components/home/HVideo.vue";
import RecommendProfession from "./components/home/HRecommend.vue";
import FriendLink from "./components/home/HFriend.vue";
import Publicize from "./components/home/HContent.vue";
import BottomMenu from '@/components/footer/BottomMenu.vue';
import Contact from '@/components/footer/Contact.vue';
import Bottom from '@/components/footer/Bottom.vue';
import {getStore} from '@/utils/utils';
export default{
  components: {
    Top,
    Head,
    HeadMenu,
    Banner,
    Notice,
    ToolBox,
    News,
    Planner,
    UpgradeNews,
    HotSpecialty,
    HotSchool,
    Video,
    RecommendProfession,
    FriendLink,
    Publicize,
    BottomMenu,
    Contact,
    Bottom
  },
  data(){
    return{
      ModuleData:[]
    }
  },
  mounted(){
    let data = getStore('ModuleList');
    if(data!=null&&data!=undefined&&data!=''&&data!=[]){
      this.ModuleData = JSON.parse(data);
    }
    new WOW({live:false}).init();//动画初始化

  }
}
</script>