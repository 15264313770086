<template>
    <div class="expertbox">
        <div class="expertcon">
            <div class="titleico">
                <div class="ico"><i></i><span>{{ModuleName}}</span><div class="clear"></div></div>
                <div class="more" @click.prevent="$router.push('/expertlist')">更多<i class="iconfont">&#xe600;</i></div>
                <div class="clear"></div>
            </div>
            <div class="expertlist">
                <div class="ebox" v-for="(item,index) in expertdata" :key="index">
                    <div class="box" @click.prevent="$router.push('/expertdetail?id='+item.Id)">
                        <div class="img" :style="{background:'url('+item.HeadImageFileUrl+') no-repeat center center / cover'}"></div>
                        <div class="txt">
                            <div class="name">{{ item.TeacherName }}
                                <span v-show="false" class="follow" :class="item.follow==true?'active':''" @click.prevent.stop="followbtn(item)">
                                    <i class="iconfont">&#xe61f;</i>
                                    {{item.follow==true?'已关注':'关注'}}
                                </span>
                                <div class="clear"></div>
                            </div>
                            <div class="tips">
                                <span v-for="(it,ind) in item.TeacherTagList" :key="ind">{{ it.TagName }}</span>
                            </div>
                        </div>
                        <div class="clear"></div>
                        <div class="mark">{{ item.Summary }}<span></span></div>
                        <div class="yuyue" @click.prevent.stop="toreservation(item.Id)">立即预约</div>
                    </div>
                </div>
                <div class="pages" v-if="expertdata.length==0">
                    <i class="iconfont">&#xe67c;</i>
                    <span>{{pagetxt}}</span>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <!-- 专家预约 -->
        <Reservation :reservationid="reservationid" ref="expertreservation"></Reservation>
    </div>
</template>
<script>
import Reservation from "../../components/reservation/reservation.vue";
import API from "@/api/config";
import { Message} from 'element-ui';
export default{
    components:{
        Reservation
    },
    props:{
        ModuleName: {
			type: String,
			default: ''
		},
    },
    data(){
        return{
            pageIndex:1,
            reservationid:"",
            expertdata:[],
            pagetxt:'未找到专家信息！'
        }
    },
    mounted(){
        this.GetTeacherList();
    },
    methods:{
        //获取专家
        GetTeacherList(){
            API.Web.GetTeacherList({pageIndex:this.pageIndex,pageSize:3}).then(res => {
                if(res.Data.Data.length>0){
                    this.expertdata = res.Data.Data;
                }
            });
        },
        //预约
        toreservation(id){
            this.$refs.expertreservation.reservationshow=true;
            this.$refs.expertreservation.layerclass='layershow';
            this.$refs.expertreservation.layerboxclass="layerboxshow";
            this.reservationid=id;
        },
        //关注
        followbtn(item){
            Message('功能开发中……');
        },
    }
}
</script>
<style lang="less" scoped>
.expertbox{
    max-width: 1420px;
    margin: auto;
    margin-top: 20px;
    user-select: none;
    .expertcon{
        margin: 0px 20px;
        border: 1px solid #eee;
        box-shadow: 0px 0px 3px 1px #ebeaea;
        .titleico{
            height: 50px;
            line-height: 48px;
            background-color: #f2f6fa;
            overflow: hidden;
            border-bottom: 1px solid #ebebeb;
            .ico{
                float: left;
                i{
                    float: left;
                    margin: 15px 15px 0px 20px;
                    width: 6px;
                    height: 19px;
                    background-color: rgb(var(--themecolor));
                }
                span{
                    font-size: 16px;
                    color: #555;
                    font-weight: bold;
                }
            }
            .more{
                float: right;
                width: 54px;
                height: 100%;
                line-height: 50px;
                color: #666;
                font-size: 12px;
                text-align: center;
                margin-right: 17px;
                i{
                    display: block;
                    transform: rotate(90deg);
                    -webkit-transform: rotate(270deg);
                    float: right;
                    color: #888;
                    font-size: 14px;
                }
            }
            .more:hover{
                cursor: pointer;
                color: rgb(var(--themecolor));
            }
        }
        .expertlist{
            margin: 10px 10px;
            .pages{
                width: 100%;
                min-height: 50px;
                line-height: 50px;
                color: #666;
                font-size: 16px;
                text-align: center;
                letter-spacing: 1px;
                margin-bottom: 10px;
                user-select: none;
                .iconfont{
                    font-size: 50px;
                    display: block;
                    margin-top: 40px;
                    color: #9b9b9b;
                }
            }
            .ebox{
                width: calc(100% / 3);
                height: 260px;
                float: left;
                .box:hover{
                    cursor: pointer;
                    box-shadow: 0px 0px 3px 1px rgba(var(--themecolor),0.4);
                }
                .box{
                    width: calc(100% - 20px);
                    height: 240px;
                    margin: 10px;
                    background-color: #fff;
                    box-shadow: 0px 0px 3px 1px #ebeaea;
                    border-radius: 5px;
                    transition: all 0.2s;
                    -webkit-transition: all 0.2s;
                    .img{
                        width: 100px;
                        height: 100px;
                        float: left;
                        margin: 10px 15px;
                        overflow: hidden;
                        border-radius: 100px;
                    }
                    .txt{
                        float: right;
                        width: calc(100% - 140px);
                        .name{
                            color: #555;
                            font-size: 20px;
                            font-weight: bold;
                            margin-top: 18px;
                            .follow{
                                padding: 2px 8px;
                                font-size: 12px;
                                color: #666;
                                font-weight: normal;
                                border: 1px solid #ddd;
                                border-radius: 20px;
                                float: right;
                                margin-right: 20px;
                                i{
                                    margin-right: 5px;
                                    color: #bdbbbb;
                                }
                            }
                            .follow:hover{
                                cursor: pointer;
                                border: 1px solid rgba(var(--themecolor),0.4);
                            }
                            .follow.active{
                                i{
                                    color: rgb(var(--themecolor));
                                }
                            }
                        }
                        .tips{
                            width: 100%;
                            height: 50px;
                            overflow: hidden;
                            margin-top: 15px;
                            span{
                                font-size: 12px;
                                color: #6a6969;
                                background-color: #f9ede0;
                                margin-right: 10px;
                                padding: 2px 15px;
                                border-radius: 20px;
                                margin-bottom: 5px;
                                display: inline-block;
                                user-select: none;
                            }
                        }
                    }
                    .mark{
                        margin: 0px 20px;
                        color: #666;
                        font-size: 13px;
                        line-height: 20px;
                        height: 40px;
                        overflow: hidden;
                        position: relative;
                        span{
                            position: absolute;
                            right: 0px;
                            bottom: 0px;
                            height: 24px;
                            width: 80px;
                            background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,1));
                        }
                    }
                    .yuyue{
                        margin: 20px 20px 0;
                        height: 40px;
                        background-color: rgba(var(--themecolor), 0.4);
                        color: #555;
                        font-size: 16px;
                        line-height: 40px;
                        text-align: center;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                    }
                    .yuyue:hover{
                        cursor: pointer;
                        background-color: rgba(var(--themecolor),0.6);
                    }
                }
            }
        }
    }
}
</style>