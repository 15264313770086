<template>
    <div class="noticebox">
        <div class="nebox">
            <div class="nelist">
                <div class="ico"><i></i><span>{{ModuleName}}</span><div class="clear"></div></div>
                <div class="swiper-container notice_swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="(item,index) in noticedata" :key="index">
                            <div class="txt" @mouseenter="noticemouseenter()" @mouseleave="noticemouseleave()">{{ item.NoticeMessage }}</div>
                        </div>
                    </div>
                </div>
                <div class="more" @click.prevent="$router.push('/noticelist');">更多<i class="iconfont">&#xe600;</i></div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
// 引入Swiper
import Swiper from 'swiper/js/swiper';
import "swiper/css/swiper.css";
import API from "@/api/config";
import { getStore } from '@/utils/utils';
export default{
    name: 'hnotice',
    props:{
        ModuleName: {
			type: String,
			default: ''
		},
    },
    data(){
        return{
            noticeswiper:null,
            PageIndex:1,
            noticedata:[]
        }
    },
    mounted(){
        let provinceId = null;
        let aear = getStore('OrgProvince');
        if(aear!=undefined&&aear!=null&&aear!=undefined&&aear!=''){
            provinceId = JSON.parse(aear).ProvinceId;
        }
        API.Web.GetNoticeList({PageIndex:this.PageIndex,PageSize:10,ExamProvinceId:provinceId}).then(res => {
            if(res.Code===0){
                this.noticedata = res.Data.Data;
                if(res.Data.Data.length==0){
                    this.noticedata = [{NoticeMessage:'暂无通知！'}];
                }
                this.$nextTick(()=>{
                    this.noticeswiper = new Swiper(".notice_swiper", {
                        direction:'vertical',
                        speed: 1000,//切换速度
                        loop: true,//开启循环
                        allowTouchMove: false,//禁止手动切换
                        autoplay: {
                            delay: 2000,//循环间隔时间
                            disableOnInteraction: false,//操作分页切换或手动滑动后继续自动切换
                        },
                        effect:'slide'
                    });
                });
            }
        });
    },
    methods:{
        //鼠标移上停止滚动
        noticemouseenter(){
            this.noticeswiper.autoplay.stop();
        },
        //鼠标移开继续滚动
        noticemouseleave(){
            this.noticeswiper.autoplay.start();
        },
    }
}
</script>
<style lang="less" scoped>
.noticebox{
    width: 100%;
    height: 60px;
    margin-top: 15px;
    user-select: none;
    .nebox{
        max-width: 1420px;
        margin: auto;
        height: 100%;
        .nelist{
            margin: 0px 20px;
            height: 100%;
            background-color: #fff;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .ico{
                width: 100px;
                height: 100%;
                float: left;
                margin-top: 19px;
                margin-left: 20px;
                i{
                    margin-right: 15px;
                    margin-top: 1px;
                    float: left;
                    width: 6px;
                    height: 19px;
                    background-color: rgb(var(--themecolor));
                }
                span{
                    font-size: 16px;
                    color: #555;
                    font-weight: bold;

                }
            }
            .swiper-container{
                width: calc(100% - 240px);
                height: 20px;
                float: left;
                overflow: hidden;
                margin-top: 20px;
                margin-left: 15px;
                .swiper-wrapper{
                    height: 100%;
                    .txt{
                        height: 100%;
                        overflow: hidden;
                        color: #555;
                        font-size: 13px;
                        letter-spacing: 1px;
                        line-height: 20px;
                        float: left;
                    }
                }
            }
            .more{
                height: 100%;
                width: 54px;
                line-height: 60px;
                float: right;
                color: #666;
                font-size: 12px;
                text-align: center;
                margin-right: 17px;
                i{
                    transform: rotate(270deg);
                    display: block;
                    color: #888;
                    font-size: 14px;
                    float: right;
                }
            }
            .more:hover{
                cursor: pointer;
                color: rgb(var(--themecolor));
            }
        }
    }
}

</style>