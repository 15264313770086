<template>
  <div class="buttonbox">
    <div class="buttonlist">
      <div class="bdata" ref="buttondatawidth">
        <div class="clear"></div>
        <div class="boxs" v-for="item in buttondata" :key="item.Id" :style="buttondatastyle" @click.prevent="ToolBoxBtn(item)">
          <div class="ico"><i class="iconfont" v-html="item.ToolIcon"></i></div>
          <div class="txt">{{ item.ToolName }}</div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import { Message } from 'element-ui';
import { getStore } from '@/utils/utils';
import API from "@/api/config";

export default {
  data() {
    return {
      buttondatastyle: "",
      buttondata: []
    }
  },
  mounted() {
    this.GetToolBoxList();// 获取工具箱
  },
  methods: {
    // 获取工具箱
    GetToolBoxList(){
      API.Web.GetToolBoxList({AppType:1}).then(res => {
        if(res.Code===0){
          this.buttondata = res.Data;
          this.buttondatastyle = "width:" + parseInt((this.$refs.buttondatawidth.clientWidth - 1) / this.buttondata.length) + "px";
        }
      });
    },
    //工具跳转
    ToolBoxBtn(item){
      if(item.LinkUrl!='/'){
        // 处理参数
        if(item.LinkUrl.indexOf('?')>-1){
          //获取用户登录信息
          let user = getStore("LoginData");
          if (user != undefined && user != null && user != "") {
            user = JSON.parse(user);
          }
          else{
            if(item.LinkUrl.indexOf('{userid}')>-1||item.LinkUrl.indexOf('{token}')>-1||item.LinkUrl.indexOf('{nickname}')>-1){
              Message({ type: 'error', message: "请先登录！" });
              this.$router.push({ path: '/login' });
              return false;
            }
          }
          if(item.LinkUrl.indexOf('{userid}')>-1){
            item.LinkUrl = item.LinkUrl.replace('{userid}',user.UserId);
          }
          if(item.LinkUrl.indexOf('{token}')>-1){
            item.LinkUrl = item.LinkUrl.replace('{token}',user.Token);
          }
          if(item.LinkUrl.indexOf('{nickname}')>-1){
            item.LinkUrl = item.LinkUrl.replace('{nickname}',user.NickName);
          }
        }
        if(item.OpenWay==0){
          if(item.LinkUrl.indexOf('http')!=-1){
            location.href = item.LinkUrl;
          }
          else{
            this.$router.push({path:item.LinkUrl});
          }
        }
        else if(item.OpenWay==1){
          if(item.LinkUrl.indexOf('http')!=-1){
            window.open(item.LinkUrl);
          }
          else{
            window.open(this.$router.resolve({path: item.LinkUrl}).href, "_blank");
          }
        }
        else{
          if(item.LinkUrl.indexOf('http')!=-1){
            window.open(item.LinkUrl);
          }
          else{
            this.$router.push({path:item.LinkUrl});
          }
        }
      }
      else{
        Message('功能开发中……');
      }
    }
  }
}
</script>
<style lang="less" scoped>
.buttonbox {
  width: 100%;
  user-select: none;

  .buttonlist {
    max-width: 1420px;
    margin: auto;

    .bdata {
      margin: 20px 20px 0;
      background-color: #fff;
      border: 1px solid #eee;
      box-shadow: 0px 0px 3px 1px #ebeaea;

      .boxs {
        height: 150px;
        float: left;
        position: relative;

        .ico {
          width: 60px;
          height: 60px;
          background-color: rgba(var(--themecolor), 0.7);
          border-radius: 10px;
          overflow: hidden;
          text-align: center;
          line-height: 60px;
          position: absolute;
          top: 30px;
          left: calc(50% - 30px);
          transition: top 0.2s;
          -webkit-transition: top 0.2s;

          i {
            color: #ebebeb;
            font-size: 36px;
          }
        }

        .txt {
          width: 100%;
          color: #464646;
          font-size: 16px;
          letter-spacing: 1px;
          text-align: center;
          line-height: 30px;
          height: 30px;
          position: absolute;
          bottom: 20px;
          left: 0px;
          text-align: center;
          transition: color 0.2s;
          -webkit-transition: color 0.2s;
        }
      }

      .boxs:hover {
        cursor: pointer;
      }

      .boxs:hover .ico i {
        color: #ffffff;
      }

      .boxs:hover .txt {
        opacity: 0.8;
        color: rgb(var(--themecolor));
      }

      .boxs:hover .ico {
        top: 22px;
      }
    }
  }
}
</style>