<template>
    <div class="risenews">
        <div class="sncon">
            <div class="snbox">
                <div class="titleico">
                    <div class="ico"><i></i><span>{{ModuleName}}</span><div class="clear"></div></div>
                </div>
                <div class="snlist">
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=0')"><div class="border"><div class="ico"><i class="iconfont" style="color: #fc7338;">&#xe605;</i><span>强基计划</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=1')"><div class="border"><div class="ico"><i class="iconfont" style="color: #eb151c;font-size: 48px;">&#xe643;</i><span>综合评价</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=2')"><div class="border"><div class="ico"><i class="iconfont" style="color: #8e1721;font-size: 42px;">&#xe742;</i><span>高校专项</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=3')"><div class="border"><div class="ico"><i class="iconfont" style="color: #0a3d25;font-size: 38px;">&#xe6bc;</i><span>军校报考</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=4')"><div class="border"><div class="ico"><i class="iconfont" style="color: #ac8a2d;font-size: 38px;">&#xe610;</i><span>定向士官</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=5')"><div class="border"><div class="ico"><i class="iconfont" style="color: #162178;font-size: 38px;">&#xe616;</i><span>公安院校</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=6')"><div class="border"><div class="ico"><i class="iconfont" style="color: #0572b6;">&#xe722;</i><span>公费师范</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=7')"><div class="border"><div class="ico"><i class="iconfont" style="color: #e19449;font-size: 38px;">&#xe76a;</i><span>优师计划</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=8')"><div class="border"><div class="ico"><i class="iconfont" style="color: #2f0973;font-size: 42px;">&#xe684;</i><span>免费医学生</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=9')"><div class="border"><div class="ico"><i class="iconfont" style="color: #dd290f;">&#xe656;</i><span>港澳高校</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=10')"><div class="border"><div class="ico"><i class="iconfont" style="color: #7c0255;font-size: 38px;">&#xe6c9;</i><span>艺术类报考</span><div class="clear"></div></div></div></div>
                    <div class="sbox" @click.prevent="$router.push('/risenewslist?id=11')"><div class="border"><div class="ico"><i class="iconfont" style="color: #c2250d;font-size: 38px;">&#xe604;</i><span>体育类报考</span><div class="clear"></div></div></div></div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    props:{
        ModuleName: {
			type: String,
			default: ''
		},
    }
}
</script>
<style lang="less" scoped>
.risenews{
    width: 100%;
    height: 220px;
    margin-top: 20px;
    user-select: none;
    .sncon{
        max-width: 1420px;
        margin: auto;
        .snbox{
            margin: 0px 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;
            .titleico{
                height: 50px;
                line-height: 48px;
                background-color: #f2f6fa;
                overflow: hidden;
                .ico{
                    i{
                        float: left;
                        margin: 15px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }
                    span{
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }
            }
            .snlist{
                width: 100%;
                .sbox{
                    width: calc(100% / 6);
                    height: 80px;
                    line-height: 80px;
                    float: left;
                    background-color: #fff;
                    text-align: center;
                    transition: background-color 0.3s;
                    -webkit-transition: background-color 0.3s;
                    .border{
                        height: 100%;
                        text-align: center;
                        border-top:1px solid #e2e2e2;
                        border-left:1px solid #e2e2e2;
                    }
                    .ico{
                        display: inline-block;
                        i{
                            color: #cccccc;
                            font-size: 40px;
                            float: left;
                        }
                        span{
                            color: #555;
                            font-size: 16px;
                            letter-spacing: 1px;
                            margin-left: 10px;
                        }
                    }
                }
                .sbox:nth-child(1) .border{
                    border-left:1px solid #f5f5f5;
                }
                .sbox:nth-child(1) .border:hover{
                    border-left:1px solid #e2e2e2;
                }
                .sbox:nth-child(7) .border{
                    border-left:1px solid #f5f5f5;
                }
                .sbox:nth-child(7) .border:hover{
                    border-left:1px solid #e2e2e2;
                }
                .sbox:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),0.1);
                }
            }
        }
    }
}
</style>