<template>
    <div class="friendbox">
        <div class="friendlist">
          <div class="fdtitle">{{ModuleName}}</div>
          <div class="fdul">
            <a target="_blank" :href="item.LinkUrl" v-for="(item,index) in FriendLinkData" :key="index">{{item.LinkName}}</a>
          </div>
        </div>
    </div>
</template>
<script>
import API from "@/api/config";
export default{
  props:{
    ModuleName: {
			type: String,
			default: ''
		},
  },
  data(){
    return {
      FriendLinkData:[]
    }
  },
  mounted(){
    this.GetFriendLinkList();
  },
  methods:{
    GetFriendLinkList(){
      API.Web.GetFriendLinkList().then(res => {
        if(res.Data.Data.length>0){
          this.FriendLinkData = res.Data.Data;
        }
      });
    },
  }
}
</script>
<style lang="less" scoped>
.friendbox{
  max-width: 1420px;
  margin: auto;
  height: 70px;
  user-select: none;
  .friendlist{
    margin: 0px 10px;
    line-height: 30px;
    margin-top: 37px;
    .fdtitle{
      width: 100%;
      color: #555;
      font-size: 24px;
      margin-left: 10px;
      margin-bottom: 10px;
    }
    .fdul{
      width: 100%;
      a{
        display: inline-block;
        padding: 0px 10px;
        color: #666;
        font-size: 14px;
      }
      a:hover{
        color: rgb(var(--themecolor));
        opacity: 0.6;
      }
    }
  }
}
</style>