<template>
    <div class="recommendwork">
        <div class="workbox">
            <div class="workcon">
                <div class="titleico">
                    <div class="ico"><i></i><span>{{ ModuleName }}</span>
                        <div class="clear"></div>
                    </div>
                    <div class="more" @click="videomorebtn()">更多<i class="iconfont">&#xe600;</i></div>
                    <div class="clear"></div>
                </div>
                <div class="worklist">
                    <div class="vibox" v-for="item in mainData" :key="item.id">
                        <div class="box" @click="openProfessionDetail(item)">
                            <div class="cimg">
                                <div class="btn"
                                    :style="{ background: 'url(' + item.CoverImage + ') no-repeat center center / cover' }"></div>
                                <div class="vname nowrap">{{ item.ProfessionName }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="hotwork">
                    <div class="hotborder">
                        <div class="rtit"><i></i><span>热门职业</span>
                            <div class="clear"></div>
                        </div>
                        <div class="rlist">
                            <div class="hw" @click="openProfessionDetail(item)" v-for="(item, index) in workdata"
                                :key="index">
                                <div class="img">
                                    <div class="m"
                                        :style="{ background: 'url(' + item.CoverImage + ') no-repeat center center / cover' }"></div>
                                </div>
                                <div class="txt">{{ item.ProfessionName }}</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
            <div class="clear"></div>
        </div>
        <div class="clear"></div>
    </div>
</template>
<script>
import API from '@/api/config'
export default {
    props: {
        ModuleName: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            mainData: [],
            workdata: [
                { id: 1, vname: "律师", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/835b2a45b3804abfbc9f94981d40b5e4.png", code: "ESI" },
                { id: 2, vname: "市场经理", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/769491e047f04d31a0a31fd811e31c80.png", code: "EC" },
                { id: 3, vname: "建筑经理", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/260c8cf54b3a477792c93721ec741ad6.png", code: "ERC" },
                { id: 4, vname: "软件工程师", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/a8bc8bcf777242b095a80b75e24c6f58.png", code: "RIC" },
                { id: 5, vname: "中学教师", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/43b1af9957e244e6ac18d05d6fbde520.png", code: "SAE" },
                { id: 6, vname: "外科医生", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/d71943d4582e4e08b52bdb7a27e245ef.png", code: "IRS" },
                { id: 7, vname: "银行综合柜员", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/a0c8fdc3e02840769aefd8c875797d83.png", code: "SCE" },
                { id: 8, vname: "时尚设计师", coverimg: "https://scjzy.obs.cn-north-4.myhuaweicloud.com/13e16c0032a54b0ab3542095bfc0997f.png", code: "AER" },
            ]
        }
    },
    mounted() {
        this.initialize()
    },
    methods: {
        openProfessionDetail(item) {
            this.$router.push({
                path: "/profession-detail",
                query: { ID: item.ID }
            });
        },

        videomorebtn() {
            this.$router.push('/profession')
        },

        initialize() {
            API.Profession.ProfessionHotList({ Number: 4 }).then(res => {
                this.workdata = res.data
            })
            API.Profession.ProfessionRecommendList({ Number: 9 }).then(res => {
                this.mainData = res.data
            })
        }
    }
}
</script>
<style lang="less" scoped>
.recommendwork {
    width: 100%;
    height: 420px;
    margin-top: 25px;
    user-select: none;

    .workbox {
        max-width: 1420px;
        margin: auto;

        .workcon {
            margin: 0px 20px;
            border: 1px solid #eee;
            box-shadow: 0px 0px 3px 1px #ebeaea;

            .titleico {
                height: 50px;
                line-height: 48px;
                background-color: #f2f6fa;
                overflow: hidden;
                border-bottom: 1px solid #ebebeb;

                .ico {
                    float: left;

                    i {
                        float: left;
                        margin: 15px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }

                    span {
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }

                .more {
                    float: right;
                    width: 54px;
                    height: 100%;
                    line-height: 50px;
                    color: #666;
                    font-size: 12px;
                    text-align: center;
                    margin-right: 17px;

                    i {
                        display: block;
                        transform: rotate(90deg);
                        -webkit-transform: rotate(270deg);
                        float: right;
                        color: #888;
                        font-size: 14px;
                    }
                }

                .more:hover {
                    cursor: pointer;
                    color: rgb(var(--themecolor));
                }
            }

            .worklist {
                width: calc(100% - 392px);
                float: left;
                margin-left: 10px;
                margin-bottom: 10px;
                height: 360px;
                overflow: hidden;

                .vibox {
                    width: calc(100% / 4);
                    height: 178px;
                    float: left;

                    .box {
                        margin: 10px 10px 0;
                        background-color: #fff;
                        position: relative;
                        overflow: hidden;

                        .cimg {
                            width: 100%;
                            height: 158px;
                            overflow: hidden;
                            position: relative;

                            .btn {
                                position: absolute;
                                width: 100%;
                                height: 100%;
                                left: 0px;
                                top: 0px;
                                z-index: 1;
                                text-align: center;
                                line-height: 136px;
                                background: rgba(0, 0, 0, 0.1);
                                transition: all 0.3s;
                                -webkit-transition: all 0.3s;
                            }

                            .vname {
                                position: absolute;
                                bottom: 0px;
                                left: 0px;
                                z-index: 2;
                                width: 100%;
                                height: 40px;
                                line-height: 40px;
                                color: #fff;
                                font-size: 14px;
                                padding-left: 10px;
                                background-color: rgba(0, 0, 0, 0.6);
                            }
                        }
                    }

                    .box:hover {
                        cursor: pointer;
                    }

                    .box:hover .cimg .btn {
                        transform: scale(1.08);
                        -webkit-transform: scale(1.08);
                    }
                }
            }

            .hotwork {
                float: right;
                width: 358px;
                margin: 10px 10px 0px;
                overflow: hidden;
                .hotborder{
                    background-color: #fff;
                    border: 1px solid #ebebeb;
                    padding-bottom: 8px;
                }
                .rtit {
                    border-bottom: 1px solid #ebebeb;
                    height: 40px;
                    line-height: 40px;

                    i {
                        float: left;
                        margin: 11px 15px 0px 20px;
                        width: 6px;
                        height: 19px;
                        background-color: rgb(var(--themecolor));
                    }

                    span {
                        font-size: 16px;
                        color: #555;
                        font-weight: bold;
                    }
                }

                .rlist {
                    padding-top: 5px;

                    .hw {
                        width: 164px;
                        height: 135px;
                        float: left;
                        margin-top: 5px;
                        margin-left: 10px;

                        .img {
                            width: 100%;
                            height: 100px;
                            position: relative;
                            overflow: hidden;

                            .m {
                                width: 100%;
                                height: 100px;
                                position: absolute;
                                left: 0px;
                                top: 0px;
                                transition: all 0.3s;
                                -webkit-transition: all 0.3s;
                            }
                        }

                        .txt {
                            color: #555;
                            font-size: 12px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            border: 1px solid #ebebeb;
                            border-top: 0px;
                            transition: border 0.3s;
                            -webkit-transition: border 0.3s;
                        }
                    }

                    .hw:hover {
                        cursor: pointer;
                    }

                    .hw:hover .img .m {
                        transform: scale(1.08);
                        -webkit-transform: scale(1.08);
                    }

                    .hw:hover .txt {
                        color: rgb(var(--themecolor));
                        border: 1px solid #d6d6d6;
                        border-top: 0px;
                    }
                }
            }
        }
    }
}</style>