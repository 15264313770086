<template>
  <div class="batch-dialog">
    <el-dialog
      :visible.sync="open"
      width="36rem"
      append-to-body
      close-on-click-modal
    >
      <template slot="title">
        <div class="head-first">{{ProvinceName}}批次列表</div>
        <div class="head-second">
          <span class="head-second-item">等效分<el-popover
            placement="bottom"
            title="等效分："
            width="200"
            trigger="hover"
            :content="'等同于'+tableHeadYear.Year1+'年分数'"
          ><img slot="reference" class="question" src="@/assets/wish/question.png">
          </el-popover>:{{ mainData.ScoreEqual || '-' }}
          </span>
          <span class="head-second-item">位次:{{ mainData.Rank }}</span>
        </div>
      </template>
      <el-table
        :data="mainData.BatchList"
        v-loading="loading"
      >
        <el-table-column label="报考批次">
          <template slot-scope="props">
            {{props.row.BatchName}}
            <el-tag v-show="props.row.Recommend" type="danger" size="mini">推荐</el-tag>
          </template>
        </el-table-column>
        <el-table-column :label="mainData.Year+'批次控制线'" align="center">
          <template slot-scope="props">
            <span>{{{'1':'文科','2':'理科'}[SubjectType]}}</span>
            <span>{{props.row.Score}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="props">
            <el-button
              @click="selectBatchItem(props.row)"
              :disabled="!props.row.IsEnroll"
              type="danger"
              size="mini"
            >开始
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
  import API from '@/api/config'
  // import {BatchList, TableHeadYear} from "@/api/common";
  import {getStore} from '@/utils/utils'

  export default {
    name: "BatchDialog",
    data () {
      return {
        open: false,
        loading: false,
        mainData: {},
        tableHeadYear: {},

        query: {},
        studentInfo: ''
      }
    },
    props: {
      wishCategory: {
        type: Number,
        default: 0
      },
      Score: {
        type: Number,
        default: 0
      },
      SubjectType: {
        type: Number,
        default: 1
      },
      ProvinceId: {
        type: String,
        default: ''
      },
      ProvinceName: {
        type: String,
        default: ''
      }
    },
    mounted () {
      this.initialize()
    },
    methods: {
      //initialize parameters
      initialize () {
        let temp = getStore('LoginData')
        this.studentInfo = temp?JSON.parse(temp):''
      },
      //open dialog
      show () {

        this.open = true
        this.loading = true

        this.query = {
          Score: this.Score,
          SubjectType: this.SubjectType,
          ProvinceId: this.ProvinceId,
        }
        this.getDefaultInfo()
      },

      selectBatchItem (row) {

        let oldWish = {

          OrgId: this.studentInfo.OrgId,
          UserId: this.studentInfo.UserId,

          BatchId: row.BatchId,
          BatchName: row.BatchName,
          lineDifference: this.query.Score - row.Score,
          Score: this.query.Score,
          SubjectType: this.query.SubjectType,
          ScoreEqual: this.mainData.ScoreEqual,
          ProvinceId: this.query.ProvinceId,
          ProvinceName: this.ProvinceName,
          Rank: this.mainData.Rank,
          tableHeadYear: this.tableHeadYear,
          wishCategory: this.wishCategory
        }
        this.$setStorage('oldWish', oldWish).then(() => {
          this.$emit('selectInfo', oldWish)

          this.open = false

        })
      },
      // get table head info
      getDefaultInfo () {
        API.Wish.TableHeadYear({
          ProvinceId: this.query.ProvinceId,
          SubjectType: this.query.SubjectType
        }).then(res => {
          this.tableHeadYear = res.data
          this.getBatchData()
        })
      },
      //get batch list data
      getBatchData () {
        API.Wish.BatchList(this.query).then(res => {
          this.mainData = res.data
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="less">
  .batch-dialog{

  }
  
  .head-first{
      text-align: center;
      font-size: 18px;
    }
    .head-second{
      margin-top: .5rem;
      text-align: center;
      font-size: 13px;
      color: #606266;
      &-item{}
    }
    .question {
      vertical-align: middle;
      width: 1rem;
      height: 1rem;
    }
    .el-dialog .el-button:first-child {
      margin-left: 0;

    &:hover {
      color: #fff;
    }
}

</style>